/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import axios from "./axios.js";
import routerGuard from "./router/routerGuard";
import RouteViewComponent from "./router/routerView";
import routeAccountReceivable from "./router/routeAccountReceivable.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "main",
          meta: { url: "/", parent: "" },
          // redirect: "/pages/login"
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("./views/pages/Main.vue"),
        },
        {
          path: "/change-log",
          name: "change-log",
          meta: { url: "/change-log", parent: "" },
          component: () => import("./views/pages/ChangeLog.vue"),
        },
        // {
        //   path: "/delivery_plan",
        //   name: "delivery_plan",
        //   meta: { url: "/delivery_plan", parent: "" },
        //   component: () => import("./views/oms/delivery_plan/index.vue"),
        // },

        /** Approval */
        {
          path: "/approval",
          name: "approval",
          meta: { url: "/approval", parent: "Approval" },
          component: () => import("./views/oms/approval/index.vue"),
        },
        {
          path: "/approval/sales-order",
          name: "Sales Order Approval",
          meta: { url: "/approval/sales-order", parent: "Approval" },
          component: () => import("./views/oms/approval_sales_order/index.vue"),
        },
        {
          path: "/approval/contract",
          name: "Contract Approval",
          meta: { url: "/approval/contract", parent: "Approval" },
          component: () => import("./views/oms/approval_contract/index.vue"),
        },
        {
          path: "/approval/reservation",
          name: "Reservation Approval",
          meta: { url: "/approval/reservation", parent: "Approval" },
          component: () => import("./views/oms/approval_reservation/index.vue"),
        },
        {
          path: "/approval/close-invoice",
          name: "Close Invoice Approval",
          meta: { url: "/approval/close-invoice", parent: "Approval" },
          component: () => import("./views/oms/approval_close/index.vue"),
        },
        {
          path: "/approval/cancel-invoice",
          name: "Cancel Invoice Approval",
          meta: {
            url: "/approval/cancel-invoice",
            parent: "Account Receivable",
          },
          component: () =>
            import("./views/oms/approval_cancel_invoice/index.vue"),
        },
        {
          path: "/approval/kitting",
          name: "Kitting Approval",
          meta: { url: "/approval/kitting", parent: "Approval" },
          component: () => import("./views/oms/kitting_management/index.vue"),
        },
        {
          path: "/approval/promotion",
          name: "Promotion",
          meta: { url: "/approval/promotion", parent: "Approval" },
          component: () => import("./views/oms/promotion/index.vue"),
        },
        {
          path: "/approval/proposal-approval",
          name: "Proposal Approval",
          meta: { url: "/approval/proposal-approval", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/proposal_approval/index.vue"),
        },
        {
          path: "/approval/force-valid",
          name: "Force Valid",
          meta: { url: "/approval/force-valid", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/force_valid/index.vue"),
        },
        {
          path: "/approval/reversal-invoice",
          name: "Reversal Invoice",
          meta: { url: "/approval/reversal-invoice", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/reversal_invoice/index.vue"),
        },
        {
          path: "/approval/dn-po",
          name: "DN With PO Ref",
          meta: { url: "/approval/dn-po", parent: "Approval" },
          component: () => import("./views/oms/approval_/dn_po/index.vue"),
        },
        {
          path: "/approval/debit-note-reversal",
          name: "Approval Reversal Debit Note",
          meta: { url: "/approval/debit-note-reversal", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/dn_reversal/index.vue"),
        },
        {
          path: "/approval/debit-note-reversal-on-approve",
          name: "Approval Reversal Debit Note",
          meta: {
            url: "/approval/debit-note-reversal-on-approve",
            parent: "Approval",
          },
          component: () =>
            import("./views/oms/approval_/dn_reversal_on_approve/index.vue"),
        },
        {
          path: "/approval/advance-invoice",
          name: "Approval Advance Invoice",
          // meta: { url: "/approval/advance-invoice", parent: "Approval" },
          component: () => import("./views/oms/approval_/advance_invoice/index.vue")
        },
        {
          path: "/approval/dn-return-reversal",
          name: "Approval Debit Note Return Reversal",
          meta: {
            url: "/approval/dn-return-reversal",
            parent: "Approval",
          },
          component: () =>
            import("./views/oms/approval_/reversal_dn_return/index.vue"),
        },
        {
          path: "/approval/ap-payment-reversal",
          name: "Approval Reversal AP Payment",
          meta: { url: "/approval/ap-payment-reversal", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/ap-payment-reversal/index.vue"),
        },
        {
          path: "/approval/dn-without-po-ref",
          name: "DN With PO Ref",
          meta: { url: "/approval/dn-without-po-ref", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/dn_without_po_ref/index.vue"),
        },
        {
          path: "/approval/ap-payment",
          name: "AP Payment",
          meta: { url: "/approval/ap-payment", parent: "Approval" },
          component: () => import("./views/oms/approval_/ap-payment/index.vue"),
        },
        {
          path: "/approval/shipment-cost",
          name: "Approval Shipment Cost",
          meta: { url: "/approval/shipment-cost", parent: "" },
          component: () =>
            import("./views/oms/approval_/shipment_cost/index.vue"),
        },
        {
          path: "/approval/reverse-ap-payment",
          name: "Reverse AP Payment",
          meta: { url: "/approval/reverse-ap-payment", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/reversal-ap-payment/index.vue"),
        },
        {
          path: "/approval/product-festives",
          name: "Product Festives",
          meta: { url: "/approval/product-festives", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/ap-product-festive/index.vue"),
        },
        {
          path: "/approval/dn-manual",
          name: "DN Manual",
          meta: { url: "/approval/dn-manual", parent: "Approval" },
          component: () => import("./views/oms/approval_/dn_manual/index.vue"),
        },
        {
          path: "/approval/cl-request",
          name: "cl-request",
          meta: { url: "/approval/cl-request", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/credit-limit-request/index.vue"),
        },
        {
          path: "/approval/cl-request-payment-term",
          name: "cl-request-payment-term",
          meta: {
            url: "/approval/cl-request-payment-term",
            parent: "Approval",
          },
          component: () =>
            import(
              "./views/oms/approval_/credit-limit-request-payment-term/index.vue"
            ),
        },
        {
          path: "/approval/payment-for-dn-reversal",
          name: "Approval Reversal Payment for DN",
          meta: {
            url: "/approval/payment-for-dn-reversal",
            parent: "Approval",
          },
          component: () =>
            import("./views/oms/approval_/payment_for_dn_reversal/index.vue"),
        },
        {
          path: "/approval/sales-price",
          name: "Sales Price Approval",
          meta: { url: "/approval/sales-price", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/sales-price/index.vue"),
        },
        {
          path: "/approval/credit-limit-request-all",
          name: "cl-request-all",
          meta: {
            url: "/approval/credit-limit-request-all",
            parent: "Approval",
          },
          component: () =>
            import("./views/oms/approval_/credit-limit-request-all/index.vue"),
        },
        {
          path: "/approval/generate-invoice",
          name: "approval generate invoice",
          meta: {
            url: "/approval/generate-invoice",
            parent: "Approval",
          },
          component: () =>
            import("./views/oms/approval_/generate_invoice/index.vue"),
        },
        /** End Approval */

        {
          path: "/approval/customer-return-v2",
          name: "Customer Return",
          meta: { url: "/approval/customer-return-v2", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/customer-return-v2/index.vue"),
        },
        {
          path: "/approval/cancel-tax-invoice",
          name: "Cancel Tax Invoice",
          meta: { url: "/approval/cancel-tax-invoice", parent: "Approval" },
          component: () =>
            import("./views/oms/approval_/reversal_tax_invoice/index.vue"),
        },
        {
          path: "/approval/canvas-item-request",
          name: "Canvas Item Request",
          component: () =>
            import("./views/oms/approval_/canvas_item_request/index.vue"),
        },
        {
          path: "/approval/canvas-item-request/:id",
          name: "Canvas Item Request Detail",
          component: () =>
            import("./views/oms/approval_/canvas_item_request/detail.vue"),
        },
        {
          path: "/inventory-container",
          name: "inventory-container",
          meta: { url: "/inventory-container", parent: "Report" },
          component: () => import("./views/oms/inventory-container/index.vue"),
        },
        {
          path: "/sales-order/import-excel",
          name: "import-excel",
          meta: { url: "/sales-order/import-excel", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/import_excel/index.vue"),
        },
        {
          path: "/sales-order/simulation",
          name: "sales-order-simulaton",
          // meta: { url: "/sales-order/simulation", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/simulation/form.vue"),
        },
        {
          path: "/sales-order-list",
          name: "SO Pool",
          // meta: { url: "/sales-order-list", parent: "Sales Order" },
          component: () => import("./views/oms/sales_order/list_v3/index.vue"),
        },
        {
          path: "/sales-order-list/:id",
          name: "SO Pool",
          // meta: { url: "/sales-order-list", parent: "Sales Order" },
          component: () => import("./views/oms/sales_order/list_v3/index.vue"),
        },
        {
          path: "/sales-order-list/print/:id",
          name: "SO Print",
          // meta: { url: "/sales-order-list", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/list_v3/monitoring/print.vue"),
        },
        {
          path: "/sales-order-form",
          name: "SO Form",
          meta: { url: "/sales-order-form", parent: "Sales Order" },
          component: () => import("./views/oms/sales_order/so_form/index.vue"),
        },
        {
          path: "/sales-order-formv3",
          name: "SO Formv3",
          meta: { url: "/sales-order-form", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/so_form_v3/index.vue"),
        },
        // {
        //   path: "/sales-order-list-v2",
        //   name: "SO Pool",
        //   meta: { url: "/sales-order-list", parent: "Sales Order" },
        //   component: () => import("./views/oms/sales_order/list_v2/index.vue"),
        // },
        {
          path: "/sales-order-list-v3",
          name: "SO Pool",
          meta: { url: "/sales-order-list", parent: "Sales Order" },
          component: () => import("./views/oms/sales_order/list_v3/index.vue"),
        },
        {
          path: "/sales-order-form-v2",
          name: "SO Form",
          meta: { url: "/sales-order-form", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/so_form_v2/index.vue"),
        },
        {
          path: "/sales-order-customer",
          name: "SO Customer",
          meta: { url: "/sales-order-customer", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/so_customer/index.vue"),
        },
        {
          path: "/contract",
          name: "Contract",
          meta: { url: "/contract", parent: "Sales Order" },
          component: () => import("./views/oms/sales_order/contract/index.vue"),
        },
        {
          path: "/sales-order-trader",
          name: "SO Trader",
          meta: { url: "/sales-order-trader", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/so_trader/index.vue"),
        },
        {
          path: "/reservation",
          name: "Reservation",
          meta: { url: "/reservation", parent: "Sales Order" },
          component: () =>
            import("./views/oms/sales_order/reservation/index.vue"),
        },
        {
          path: "/setting/company",
          name: "company",
          meta: { url: "/setting/company", parent: "Setting" },
          component: () => import("./views/oms/setting/company/index.vue"),
        },
        {
          path: "/setting/company/edit/:id",
          name: "company-edit",
          meta: { url: "/setting/company", parent: "Setting" },
          component: () => import("./views/oms/setting/company/edit.vue"),
        },
        {
          path: "/setting/user",
          name: "user",
          meta: { url: "/setting/user", parent: "Setting" },
          component: () => import("./views/oms/setting/user/index.vue"),
        },
        {
          path: "/setting/user/edit/:id",
          name: "user-edit",
          component: () => import("./views/oms/setting/user/form.vue"),
        },
        {
          path: "/setting/user/add",
          name: "user-add",
          component: () => import("./views/oms/setting/user/form.vue"),
        },
        {
          path: "/setting/role",
          name: "role",
          component: () => import("./views/oms/setting/role/index.vue"),
        },
        {
          path: "/setting/role/edit/:id",
          name: "role-edit",
          component: () => import("./views/oms/setting/role/form.vue"),
        },
        {
          path: "/setting/role/add",
          name: "role-add",
          component: () => import("./views/oms/setting/role/form.vue"),
        },
        {
          path: "/setting/role-menu",
          name: "role-menu",
          component: () => import("./views/oms/setting/role_menu/index.vue"),
        },
        {
          path: "/setting/role-menu/edit/:id",
          name: "edit-role-menu",
          component: () => import("./views/oms/setting/role_menu/form-v1.vue"),
        },
        {
          path: "/setting/minimum-order-quantity",
          name: "minimum-order-quantity",
          component: () =>
            import("./views/oms/setting/minimum-order-quantity/index.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("./views/profile/profile.vue"),
        },
        {
          path: "/profile/edit",
          name: "edit-profile",
          component: () => import("./views/profile/edit.vue"),
        },
        {
          path: "/profile/change-password",
          name: "change-password",
          component: () => import("./views/profile/change_password.vue"),
        },
        {
          path: "/kitting-management",
          name: "kitting-management",
          component: () => import("./views/oms/kitting_management/index.vue"),
        },
        {
          path: "/promotion",
          name: "promotion",
          // component: () => import("./views/oms/promotion/index.vue"),
          component: () =>
            import("./views/oms/promotion_v2/discount/index.vue"),
        },
        {
          path: "/promotion-budget",
          name: "Promotion Budget",
          component: () => import("./views/oms/promotion_v2/budget/index.vue"),
        },
        {
          path: "/promotion-budget/approval",
          name: "Promotion Budget Approval",
          component: () =>
            import("./views/oms/promotion_v2/budget/approval/index.vue"),
        },
        {
          path: "/promotion-v2",
          name: "Promotion V2",
          component: () =>
            import("./views/oms/promotion_v2/discount/index.vue"),
        },
        {
          path: "/promotion-v2/approval",
          name: "Promotion V2 Approval",
          component: () =>
            import("./views/oms/promotion_v2/discount/approval/index.vue"),
        },
        {
          path: "/billing",
          name: "billing",
          meta: { url: "/billing", parent: "Account Receivable" },
          component: () => import("./views/oms/billing/index.vue"),
        },
        {
          path: "/billing-customer",
          name: "billing-customer",
          meta: { url: "/billing-customer", parent: "Account Receivable" },
          component: () => import("./views/oms/billing/index.vue"),
        },
        {
          path: "/consignment-sales",
          name: "Consignment Sales",
          component: () => import("./views/oms/billing/index.vue"),
        },
        {
          path: "/reconcile",
          name: "reconcile",
          component: () => import("./views/oms/reconcile/index.vue"),
        },
        {
          path: "/payment-cod",
          name: "payment-cod",
          component: () => import("./views/oms/reconcile_cod/index.vue"),
        },
        {
          path: "/reconcile-cod",
          name: "reconcile-cod",
          component: () => import("./views/oms/reconcile_cod/index.vue"),
        },
        {
          path: "/payment-customer",
          name: "payment-customer",
          component: () =>
            import("./views/oms/approval_payment_customer/index.vue"),
        },
        {
          path: "/customer-approval",
          name: "customer-approval",
          component: () => import("./views/oms/master/customer-approval"),
        },
        // {
        //   path: "/simple_delivery_plan",
        //   name: "simple_delivery_plan",
        //   meta: { url: "/simple_delivery_plan", parent: "" },
        //   component: () => import("./views/oms/simple_delivery_plan/index.vue"),
        // },
        {
          path: "/delivery_plan",
          name: "delivery_plan",
          meta: { url: "/delivery_plan", parent: "" },
          component: () => import("./views/oms/delivery_plan2/index.vue"),
        },
        // {
        //   path: "/delivery_plan/so-sku-view/:wh_id",
        //   name: "delivery_plan",
        //   meta: { url: "/delivery_plan/so-sku-view", parent: "" },
        //   component: () => import("./views/oms/delivery_plan2/manual/view.vue"),
        // },
        // {
        //   path: "/delivery_plan/edit/:sku_code",
        //   name: "delivery_plan",
        //   meta: { url: "/delivery_plan/edit", parent: "" },
        //   component: () => import("./views/oms/delivery_plan2/manual/edit.vue"),
        // },
        // {
        //   path: "/delivery_plan/create",
        //   name: "delivery_plan_draft",
        //   meta: { url: "/delivery_plan/create", parent: "" },
        //   component: () => import("./views/oms/delivery_plan2/create/create.vue"),
        // },
        {
          path: "master",
          component: () => import("./views/oms/master"),
          children: [
            {
              path: "sku-view",
              name: "sku-view",
              component: () => import("./views/oms/master/sku-view"),
            },
            {
              path: "customers",
              name: "customers",
              component: () => import("./views/oms/master/customer"),
            },

            {
              path: "customers/edit/:id",
              name: "customers-edit",
              component: () => import("./views/oms/master/customer"),
            },
            {
              path: "customer-address/:id",
              name: "customer-address",
              component: () => import("./views/oms/master/customer-address"),
            },
            {
              path: "pricing-group/line/:id",
              name: "pricing-group",
              component: () => import("./views/oms/master/pricing-group"),
            },
            {
              path: "business-partner",
              name: "business-partner",
              component: () => import("./views/oms/master/business-partner"),
            },
            {
              path: "business-partner-address/:id",
              name: "business-partner-address",
              component: () =>
                import("./views/oms/master/business-partner-address"),
            },
            {
              path: "sales-area",
              name: "sales-area",
              component: () => import("./views/oms/master/sales-area"),
            },
            {
              path: "customer-category",
              name: "customer-category",
              component: () => import("./views/oms/master/customer-category"),
            },
            {
              path: "customer-group",
              name: "customer-group",
              component: () => import("./views/oms/master/customer-group"),
            },
            {
              path: "payment-term",
              name: "payment-term",
              component: () => import("./views/oms/master/payment-term"),
            },
            {
              path: "accounting-period",
              name: "accounting-period",
              component: () => import("./views/oms/master/accounting-period"),
            },
            {
              path: "combo",
              name: "combo",
              component: () => import("./views/oms/master/combo"),
            },
            {
              path: "tax-invoice-xml",
              name: "tax-invoice-xml",
              component: () => import("./views/oms/master/tax-invoice-xml"),
            },
            {
              path: "sales-channel",
              name: "sales-channel",
              component: () => import("./views/oms/master/sales-channel"),
            },
            {
              path: "sales-quota",
              name: "sales-quota",
              component: () => import("./views/oms/master/sales-quota"),
            },
            {
              path: "target-management",
              name: "target-management",
              props: (route) => ({
                active: route.query.active ? parseInt(route.query.active) : 0,
              }),
              component: () => import("./views/oms/master/target-management"),
            },
            {
              path: "target-management/distribute/:id",
              name: "distribute",
              component: () =>
                import("./views/oms/master/target-management/distribute"),
            },
            {
              path: "work-team",
              name: "work-team",
              component: () => import("./views/oms/master/work-team"),
            },
            {
              path: "work-team-personal/:id",
              name: "work-team-personal",
              component: () => import("./views/oms/master/work-team-personal"),
            },
            {
              path: "pricing-management",
              name: "pricing-management",
              component: () => import("./views/oms/master/pricing-management"),
            },
            {
              path: "pricing-management/list-price/edit/:id",
              name: "pricing-management-edit-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/ListPriceForm.vue"
                ),
            },
            {
              path: "pricing-management/list-price/detail/:id",
              name: "pricing-management-detail-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/ListPriceDetail.vue"
                ),
            },
            {
              path: "pricing-management/list-price/create",
              name: "pricing-management-create-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/ListPriceForm.vue"
                ),
            },
            {
              path: "pricing-management/discount/create",
              name: "pricing-management-create-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/DiscountForm.vue"
                ),
            },
            {
              path: "pricing-management/discount/edit/:id",
              name: "pricing-management-edit-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/DiscountForm.vue"
                ),
            },
            {
              path: "pricing-management-src",
              name: "pricing-management-src",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-src/ListPrice.vue"
                ),
            },
            {
              path: "tax-invoice-period",
              name: "tax-invoice-period",
              component: () => import("./views/oms/master/tax-invoice-period"),
            },
            {
              path: "pricing-management-src/list-price/create",
              name: "pricing-management-src-create-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-src/ListPriceForm.vue"
                ),
            },
            {
              path: "pricing-management-src/list-price/edit/:id",
              name: "pricing-management-src-edit-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-src/ListPriceForm.vue"
                ),
            },
            {
              path: "pricing-management-src/list-price/detail/:id",
              name: "pricing-management-src-detail-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-src/ListPriceDetail.vue"
                ),
            },
            {
              path: "tax",
              name: "tax",
              component: () => import("./views/oms/master/tax"),
            },
            {
              path: "pricing-management/list-price/create-partial",
              name: "pricing-management-create-list-price-partial",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/ListPricePartialForm.vue"
                ),
            },
            {
              path: "pricing-management/list-price/edit-partial/:id",
              name: "pricing-management-edit-partial-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management/ListPricePartialForm.vue"
                ),
            },
            {
              path: "currency-exchange-rate",
              name: "currency-exchange-rate",
              component: () =>
                import("./views/oms/master/currency-exchange-rate"),
            },

            // PRICE RULE V2
            {
              path: "pricing-management-v2",
              name: "pricing-management-v2",
              component: () =>
                import("./views/oms/master/pricing-management-v2"),
            },

            // list price
            {
              path: "pricing-management-v2/list-price/edit/:id",
              name: "pricing-management-v2-edit-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-price/form.vue"
                ),
            },
            {
              path: "pricing-management-v2/list-price/detail/:id",
              name: "pricing-management-v2-detail-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-price/detail.vue"
                ),
            },
            {
              path: "pricing-management-v2/list-price/create",
              name: "pricing-management-v2-create-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-price/form.vue"
                ),
            },
            {
              path: "pricing-management-v2/list-price/create-partial",
              name: "pricing-management-v2-create-list-price-partial",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-price/form-partial.vue"
                ),
            },
            {
              path: "pricing-management-v2/list-price/edit-partial/:id",
              name: "pricing-management-v2-edit-partial-list-price",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-price/form-partial.vue"
                ),
            },

            // discount total
            {
              path: "pricing-management-v2/discount/create",
              name: "pricing-management-v2-create-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-discount-total/form.vue"
                ),
            },
            {
              path: "pricing-management-v2/discount/edit/:id",
              name: "pricing-management-v2-edit-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-discount-total/form.vue"
                ),
            },

            // discount item
            {
              path: "pricing-management-v2/list-discount/edit/:id",
              name: "pricing-management-v2-edit-list-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-discount-item/form.vue"
                ),
            },
            {
              path: "pricing-management-v2/list-discount/detail/:id",
              name: "pricing-management-v2-detail-list-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-discount-item/detail.vue"
                ),
            },
            {
              path: "pricing-management-v2/list-discount/create",
              name: "pricing-management-v2-create-list-discount",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/regular-discount-item/form.vue"
                ),
            },

            // discount set
            {
              path: "pricing-management-v2/discount-set/edit/:id",
              name: "pricing-management-v2-edit-discount-set",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/discount-set/form.vue"
                ),
            },
            {
              path: "pricing-management-v2/discount-set/detail/:id",
              name: "pricing-management-v2-detail-discount-set",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/discount-set/detail.vue"
                ),
            },
            {
              path: "pricing-management-v2/discount-set/create",
              name: "pricing-management-v2-create-discount-set",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/discount-set/form.vue"
                ),
            },

            // price set
            {
              path: "pricing-management-v2/price-set/edit/:id",
              name: "pricing-management-v2-edit-price-set",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/price-set/form.vue"
                ),
            },
            {
              path: "pricing-management-v2/price-set/detail/:id",
              name: "pricing-management-v2-detail-price-set",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/price-set/detail.vue"
                ),
            },
            {
              path: "pricing-management-v2/price-set/create",
              name: "pricing-management-v2-create-price-set",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v2/price-set/form.vue"
                ),
            },
            // ===================
            //  Sales Price aka Pricing management v3
            // ===================
            {
              path: "pricing-management-v3",
              name: "pricing-management-v3",
              component: () =>
                import("./views/oms/master/pricing-management-v3"),
            },
            {
              path: "pricing-management-v3/form",
              name: "pricing-management-v3-form",
              component: () =>
                import("./views/oms/master/pricing-management-v3/form.vue"),
            },
            {
              path: "pricing-management-v3/form/:id",
              name: "pricing-management-v3-form-id",
              component: () =>
                import("./views/oms/master/pricing-management-v3/form.vue"),
            },
            {
              path: "pricing-management-v3/create/list",
              name: "pricing-management-v3-create-list",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v3/draft/index.vue"
                ),
            },
            {
              path: "pricing-management-v3/upload/form",
              name: "pricing-management-v3-upload-form",
              component: () =>
                import(
                  "./views/oms/master/pricing-management-v3/upload/index.vue"
                ),
            },
            // END PRICE RULE V2
            {
              path: "tax-invoice",
              name: "tax-invoice",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () => import("./views/oms/master/tax_invoice"),
            },
            //Product Festives Route
            {
              path: "product-festives",
              name: "product-festives",
              component: () =>
                import("./views/oms/master/product-festives/index.vue"),
            },
            {
              path: "product-festives/create",
              name: "product-festives-create",
              component: () =>
                import("./views/oms/master/product-festives/Create.vue"),
            },
            {
              path: "product-festives/detail/:id/edit",
              name: "product-festives-edit",
              component: () =>
                import("./views/oms/master/product-festives/Edit.vue"),
            },
            {
              path: "tolerance",
              name: "tolerance",
              component: () => import("./views/oms/master/tolerance/index.vue"),
            },
            //promotion master
            {
              path: "promotion",
              name: "promotion",
              component: () => import("./views/oms/master/promotion_v2"),
            },
            {
              path: "product-festives/detail/:id/show",
              name: "product-festives-show",
              component: () =>
                import("./views/oms/master/product-festives/Show.vue"),
            },
            //product festive import
            {
              path: "product-festives/import",
              name: "product-festives-import",
              component: () =>
                import("./views/oms/master/product-festives/import/index.vue"),
            },
            {
              path: "reason",
              name: "reason",
              component: () => import("./views/oms/master/reason"),
            },
            {
              path: "expense-type",
              name: "expense-type",
              component: () =>
                import("./views/oms/master/expense-type/index.vue"),
            },
            {
              path: "sn-tax-configuration",
              name: "sn-tax-configuration",
              component: () =>
                import("./views/oms/master/sn-tax-configuration/index.vue"),
            },
          ],
        },
        {
          path: "setting",
          component: () => import("./views/oms/setting"),
          children: [
            {
              path: "menu",
              name: "menu",
              meta: { url: "/setting/menu", parent: "Setting" },
              component: () => import("./views/oms/setting/menu"),
            },
            {
              path: "finance",
              name: "finance",
              component: () => import("./views/oms/setting/finance/index"),
            },
            {
              path: "approval",
              name: "approval",
              component: () => import("./views/oms/setting/approval/index"),
            },
            {
              path: "approval/form",
              name: "approval-form",
              component: () => import("./views/oms/setting/approval/form"),
            },
            {
              path: "approval/form/:id",
              name: "approval-form-edit",
              component: () => import("./views/oms/setting/approval/form"),
            },
            {
              path: "sort-menu",
              name: "sort-menu",
              meta: { url: "/setting/menu", parent: "Setting" },
              component: () => import("./views/oms/setting/menu/sorting.vue"),
            },
            {
              path: "user-work-number",
              name: "user-work-number",
              component: () =>
                import("./views/oms/setting/user-work-number/index"),
            },
            {
              path: "rounding",
              name: "rounding",
              component: () => import("./views/oms/setting/rounding/index"),
            },
            {
              path: "posting-rule",
              name: "posting-rule",
              component: () => import("./views/oms/setting/posting-rule/index"),
            },
            {
              path: "master-number",
              name: "master-number",
              component: () =>
                import("./views/oms/setting/master-number/index"),
            },
            {
              path: "document-number",
              name: "document-number",
              component: () =>
                import("./views/oms/setting/document-number/index"),
            },
            {
              path: "/setting/setting-oms",
              name: "setting-oms",
              component: () =>
                import("./views/oms/setting/setting-oms/index.vue"),
            },
            {
              path: "/setting/allocation-rule",
              name: "allocation-rule",
              component: () =>
                import("./views/oms/setting/allocation-rule/index.vue"),
            },
            // {
            //   path: "sales-pricing-method",
            //   name: "sales-pricing-method",
            //   component: () => import("./views/oms/setting/sales-pricing-method/index")
            // },
            {
              path: "write-off-parameters",
              name: "write-off-parameters",
              component: () =>
                import("./views/oms/setting/write-off-parameters/index.vue"),
            },
            {
              path: "write-off-parameters-create",
              name: "write-off-parameters-create",
              component: () =>
                import("./views/oms/setting/write-off-parameters/create"),
            },
            {
              path: "write-off-parameters-edit/:id",
              name: "write-off-parameters-edit",
              component: () =>
                import("./views/oms/setting/write-off-parameters/edit"),
            },
          ],
        },
        {
          path: "report",
          component: () => import("./views/oms/report"),
          children: [
            {
              path: "stock-fulfillment",
              name: "stock-fulfillment",
              component: () => import("./views/oms/report/stock-fulfillment"),
            },
            {
              path: "bank-statement",
              name: "bank-statement",
              component: () => import("./views/oms/report/bank_statement"),
            },
            {
              path: "cash-deposit",
              name: "cash-deposit",
              component: () =>
                import("./views/oms/report/cash_bank_management"),
            },
            {
              path: "customer-limit",
              name: "customer-limit",
              component: () => import("./views/oms/customer_limit/index"),
            },
            {
              path: "journal",
              name: "journal",
              component: () => import("./views/oms/report/journal/index"),
            },
            {
              path: "stock-territory",
              name: "stock-territory",
              component: () => import("./views/oms/report/stock-territory"),
            },
            {
              path: "ocr",
              name: "ocr",
              component: () => import("./views/oms/report/ocr"),
            },
            {
              path: "purchase-order",
              name: "purchase-order",
              component: () => import("./views/oms/report/purchase-order"),
            },
            {
              path: "scrap",
              name: "scrap",
              component: () => import("./views/oms/report/scrap"),
            },
            {
              path: "inbound",
              name: "inbound",
              component: () => import("./views/oms/report/inbound"),
            },
            {
              path: "stock-opname",
              name: "stock-opname",
              component: () => import("./views/oms/report/stock-opname"),
            },
            {
              path: "promotion",
              name: "promotion",
              component: () => import("./views/oms/report/promotion"),
            },
            {
              path: "sales-order",
              name: "sales-order-profitability",
              component: () => import("./views/oms/report/sales-order"),
            },
            {
              path: "so-do-gi-bill",
              name: "so-do-gi-bill",
              component: () => import("./views/oms/report/so-do-gi-bill"),
            },
            {
              path: "o2cflow",
              name: "o2cflow",
              component: () => import("./views/oms/report/o2cflow"),
            },
            {
              path: "bill-order",
              name: "bill-order",
              component: () => import("./views/oms/report/bill-order"),
            },
            {
              path: "ar-aging",
              name: "ar-aging",
              component: () => import("./views/oms/report/ar-aging"),
            },
            {
              path: "ar-aging-mix",
              name: "ar-aging-mix",
              component: () => import("./views/oms/report/ar-aging-mix"),
            },
            {
              path: "ar-trans",
              name: "ar-trans",
              component: () => import("./views/oms/report/ar-trans"),
            },
            {
              path: "sales-order-o2c",
              name: "sales-order-o2c",
              component: () => import("./views/oms/report/sales-order-o2c"),
            },
            {
              path: "payment",
              name: "payment",
              component: () => import("./views/oms/report/payment"),
            },
            {
              path: "debit-note-claim-off-invoice",
              name: "debit-note-claim-off-invoice",
              component: () => import("./views/oms/report/debit-note-claim"),
            },
            {
              path: "debit-note",
              name: "debit-note-note",
              component: () => import("./views/oms/report/debit-note"),
            },
            {
              path: "debit-note-return",
              name: "debit-note-return",
              component: () => import("./views/oms/report/debit-note-return"),
            },
            {
              path: "claim-promo",
              name: "claim-promo",
              component: () => import("./views/oms/report/claim-promo"),
            },
            {
              path: "ar-payment",
              name: "ar-payment",
              component: () => import("./views/oms/report/ar-payment"),
            },
            {
              path: "journal-monitoring",
              name: "journal-monitoring",
              component: () =>
                import("./views/oms/report/journal-monitoring/index"),
            },
            {
              path: "monitoring-coa",
              name: "monitoring-coa",
              component: () =>
                import("./views/oms/report/monitoring_coa/index"),
            },
            {
              path: "shipment-planning",
              name: "shipment-planning",
              component: () => import("./views/oms/report/shipment-planning"),
            },
            {
              path: "invoice-exchange",
              name: "invoice-exchange",
              component: () => import("./views/oms/report/invoice-exchange"),
            },
            {
              path: "supplier-invoice",
              name: "supplier-invoice",
              component: () => import("./views/oms/report/ap"),
            },
            {
              path: "zdr-sales",
              name: "zdr-sales",
              component: () => import("./views/oms/report/zdr-sales"),
            },
            {
              path: "zdr-bill",
              name: "zdr-bill",
              component: () => import("./views/oms/report/zdr-bill"),
            },
            {
              path: "cmi-monitoring",
              name: "cmi-monitoring",
              component: () => import("./views/oms/report/cmi-monitoring"),
            },
            {
              path: "fbl5n",
              name: "fbl5n",
              component: () => import("./views/oms/report/fbl5n"),
            },
          ],
        },
        {
          path: "/sales-return-management",
          name: "sales-return-management",
          component: () =>
            import("./views/oms/sales_retur/sales_retur_management/index.vue"),
        },
        {
          path: "/sales-return-without-ref",
          name: "sales-return-without-ref",
          component: () =>
            import("./views/oms/sales_retur/sales_retur_without_ref/index.vue"),
        },
        {
          path: "/good-issue-return",
          name: "good-issue-return",
          component: () =>
            import("./views/oms/sales_retur/good_issue_return/index.vue"),
        },
        {
          path: "/dashboard",
          name: "dashboard",
          meta: { url: "/dashboard", parent: "" },
          component: () => import("./views/oms/dashboard"),
        },
        {
          path: "import",
          component: () => import("./views/oms/import/index"),
          children: [
            {
              path: "master",
              name: "master",
              component: () => import("./views/oms/import/master/index"),
            },
            {
              path: "price",
              name: "price",
              component: () => import("./views/oms/import/price/index"),
            },
            {
              path: "customer",
              name: "customer",
              component: () => import("./views/oms/import/customer/index"),
            },
            {
              path: "so-to-invoice",
              name: "so-to-invoice",
              component: () => import("./views/oms/import/so-to-invoice/index"),
            },
            {
              path: "promotion-claim",
              name: "promotion-claim",
              component: () =>
                import("./views/oms/import/promotion-claim/index"),
            },
            {
              path: "free-goods",
              name: "free-goods",
              component: () => import("./views/oms/import/free-goods/index"),
            },
            {
              path: "bulk-update-master",
              name: "bulk-update-master",
              component: () =>
                import("./views/oms/import/bulk-update-master/index"),
            },
            {
              path: "master-promotion",
              name: "import-master-promotion",
              component: () =>
                import("./views/oms/import/master/promotion/index"),
            },
            {
              path: "begbal",
              name: "begbal",
              component: () => import("./views/oms/import/begbal/index.vue"),
            },
            {
              path: "cmi",
              name: "cmi",
              component: () => import("./views/oms/import/cmi/index"),
            },
          ],
        },
        // ===================
        //  Finance
        // ===================
        {
          path: "/finance",
          name: "finance",
          meta: { url: "/finance" },
          component: RouteViewComponent,
          children: [
            {
              path: "account-receivable",
              name: "finance-account-receivable",
              meta: { url: "/finance" },
              component: RouteViewComponent,
              children: routeAccountReceivable,
            },
            {
              path: "payment",
              name: "Payment",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () => import("./views/oms/finance/payment/index.vue"),
            },
            // {
            //   path: "payment/create",
            //   name: "Payment",
            //   // meta: { url: "/", parent: "" },
            //   // redirect: "/pages/login"
            //   component: () => import("./views/oms/finance/payment/create.vue"),
            // },
            {
              path: "payment_receive",
              name: "Payment Receive",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/payment_receive/index.vue"),
            },

            {
              path: "/finance/approval/payment-reversal",
              name: "approval",
              // meta: { url: "/approval/payment_reversal", parent: "Approval" },
              component: () =>
                import("./views/oms/finance/approval/payment_reversal"),
            },
            {
              path: "/finance/approval/cash-deposit",
              name: "approval-cash-deposit",
              // meta: { url: "/approval/payment_reversal", parent: "Approval" },
              component: () =>
                import("./views/oms/finance/approval/cash_bank_management"),
            },
            {
              path: "/finance/approval/invoice-reversal",
              name: "approval-reversal-invoice",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/approval/invoice_reversal"),
            },
            {
              path: "/finance/approval/approval-cn",
              name: "approval",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/approval/credit_note_reversal"),
            },
            {
              path: "cash-bank-management",
              name: "cash-bank-management",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/cash_bank_management/index.vue"),
            },
            {
              path: "cash-bank-management-manual",
              name: "cash-bank-management-manual",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/cash_bank_management/index-manual.vue"
                ),
            },
            {
              path: "approval-cash-deposit-reversal",
              name: "approval-cash-deposit-reversal",
              component: () =>
                import(
                  "./views/oms/finance/approval/reversal_cash_deposit/index.vue"
                ),
            },
            {
              path: "customer-deposit",
              name: "customer-deposit",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/customer_deposit/index.vue"),
            },
            {
              path: "customer-deposit-settlement",
              name: "customer-deposit-settlement",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_deposit_settlement/index.vue"
                ),
            },
            {
              path: "customer-deposit-settlement/:id",
              name: "customer-deposit-settlement.detail",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_deposit_settlement/detail.vue"
                ),
            },
            {
              path: "customer-deposit-settlement/view/:id",
              name: "customer-deposit-settlement.detail.view",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_deposit_settlement/detail.vue"
                ),
            },
            {
              path: "customer-deposit-settlement/detail/:id",
              name: "customer-deposit-settlement.form",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_deposit_settlement/form.vue"
                ),
            },
            {
              path: "customer-deposit-settlement/detail/view/:id",
              name: "customer-deposit-settlement.form.view",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_deposit_settlement/form.vue"
                ),
            },
            {
              path: "customer-deposit/:id",
              name: "customer-deposit.detail",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/customer_deposit/detail.vue"),
            },
            {
              path: "/finance/account-receivable/transfer-customer-deposit",
              name: "transfer-customer-deposit",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/transfer_customer_deposit/index.vue"
                ),
            },
            // {
            //   path: "approval-cash-deposit",
            //   name: "approval-cash-deposit",
            //   component: () =>
            //     import(
            //       "./views/oms/finance/approval/reversal_cash_deposit/index.vue"
            //     ),
            // },
            {
              path: "approval-customer-deposit-reversal",
              name: "approval-customer-deposit-reversal",
              component: () =>
                import(
                  "./views/oms/finance/approval/reversal_customer_deposit/index.vue"
                ),
            },
            {
              path: "bank-statement",
              name: "bank-statement",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/bank_statement/index.vue"),
            },
            {
              path: "bank-statement/:id",
              name: "bank-statement-id",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/bank_statement/detail/index.vue"),
            },
            // tambahan
            {
              path: "approval-bank-statement",
              name: "approval-bank-statement",
              //   // meta: { url: "/", parent: "" },
              //   // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/approval/bank_statement/index.vue"),
            },
            {
              path: "deposit-payment",
              name: "deposit-payment",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/deposit_payment/index.vue"),
            },
            {
              path: "customer-payment-account",
              name: "customer-payment-account",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_payment_account/index.vue"
                ),
            },
            {
              path: "credit-note-promo-type",
              name: "credit-note-promo-type",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/credit_note_promo_type/index.vue"),
            },
            {
              path: "credit-note",
              name: "credit-note",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/credit_note/index.vue"),
            },
            {
              path: "create-credit-note-claim",
              name: "create-credit-note-claim",
              component: () =>
                import("./views/oms/finance/credit_note/credit_note_form.vue"),
            },
            {
              path: "edit-credit-note-claim/:ID",
              name: "edit-credit-note-claim",
              component: () =>
                import("./views/oms/finance/credit_note/credit_note_edit.vue"),
            },
            {
              path: "create-credit-note-manual",
              name: "create-credit-note-manual",
              component: () =>
                import("./views/oms/finance/credit_note/create.vue"),
            },
            {
              path: "edit-credit-note-manual/:ID",
              name: "edit-credit-note-manual",
              component: () =>
                import("./views/oms/finance/credit_note/create.vue"),
            },
            {
              path: "view-credit-note-manual/:ID",
              name: "view-credit-note-manual",
              component: () =>
                import("./views/oms/finance/credit_note/create.vue"),
            },
            {
              path: "approval/credit-note",
              name: "approval-credit-note",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/approval_/finance/credit_note/index.vue"),
            },
            {
              path: "shipment-cost",
              name: "shipment-cost",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/shipment_cost/index.vue"),
            },
            {
              path: "shipment-cost-execution",
              name: "shipment-cost-execution",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/shipment_cost_execution/index.vue"),
            },
            {
              path: "write-off",
              name: "write-off",
              component: RouteViewComponent,
              children: [
                {
                  path: "approval",
                  name: "approval",
                  component: RouteViewComponent,
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "./views/oms/approval_/finance/write-off/index.vue"
                        ),
                    },
                    {
                      path: ":id/find",
                      name: "approval-find-write-off",
                      component: () =>
                        import(
                          "./views/oms/approval_/finance/write-off/edit.vue"
                        ),
                    },
                    {
                      path: ":id/show",
                      name: "approval-show-write-off",
                      component: () =>
                        import(
                          "./views/oms/approval_/finance/write-off/show.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("./views/oms/finance/write-off/index.vue"),
                },
                {
                  path: "create",
                  name: "create",
                  component: () =>
                    import("./views/oms/finance/write-off/create.vue"),
                },
                {
                  path: ":id/find",
                  name: "find-write-off",
                  component: () =>
                    import("./views/oms/finance/write-off/edit.vue"),
                },
                {
                  path: ":id/confirm",
                  name: "find-write-off-confirm",
                  component: () =>
                    import("./views/oms/finance/write-off/form-confirm.vue"),
                },
                {
                  path: ":id/show",
                  name: "show-write-off",
                  component: () =>
                    import("./views/oms/finance/write-off/show.vue"),
                },
              ],
            },
            {
              path: "ar-clearing-gov",
              name: "ar-clearing-gov",
              component: RouteViewComponent,
              children: [
                {
                  path: "approval",
                  name: "approval",
                  component: RouteViewComponent,
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "./views/oms/approval_/finance/write-off/index.vue"
                        ),
                    },
                    {
                      path: ":id/find",
                      name: "approval-find-write-off",
                      component: () =>
                        import(
                          "./views/oms/approval_/finance/write-off/edit.vue"
                        ),
                    },
                    {
                      path: ":id/show",
                      name: "find-write-off",
                      component: () =>
                        import(
                          "./views/oms/approval_/finance/write-off/show.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("./views/oms/finance/ar_clearing/index.vue"),
                },
                // {
                //   path: "create",
                //   name: "create",
                //   component: () =>
                //     import("./views/oms/finance/ar_clearing/create.vue"),
                // },
                // {
                //   path: ":id/find",
                //   name: "find_ar_clearing",
                //   component: () =>
                //     import("./views/oms/finance/ar_clearing/edit.vue"),
                // },
                // {
                //   path: ":id/confirm",
                //   name: "find_ar_clearing_confirm",
                //   component: () =>
                //     import("./views/oms/finance/ar_clearing/form-confirm.vue"),
                // },
                // {
                //   path: ":id/show",
                //   name: "show_ar_clearing",
                //   component: () =>
                //     import("./views/oms/finance/ar_clearing/show.vue"),
                // },
              ],
            },
            {
              path: "payment-collection",
              name: "payment-collection",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/payment_collection/index.vue"),
            },
            {
              path: "pod-processing",
              name: "POD Processing",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/pod-processing/index.vue"),
            },
            {
              path: "ar-clearing",
              name: "ar-clearing",
              component: () =>
                import("./views/oms/finance/new_ar_clearing/index.vue"),
            },
            {
              path: "customer-invoice-exchange",
              name: "Customer Invoice Exchange",
              component: () =>
                import(
                  "./views/oms/finance/customer_invoice_exchange/index.vue"
                ),
            },
            {
              path: "customer-invoice-exchange/create",
              name: "Customer Invoice Exchange Creation",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_invoice_exchange/create-form.vue"
                ),
            },
            {
              path: "customer-invoice-exchange/edit/:ids",
              name: "Customer Invoice Exchange Edit",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_invoice_exchange/edit-form.vue"
                ),
            },
            {
              path: "customer-invoice-exchange/detail/:ids",
              name: "Customer Invoice Exchange Detail",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import(
                  "./views/oms/finance/customer_invoice_exchange/detail.vue"
                ),
            },
            {
              path: "invoice-exchange",
              name: "Invoice Exchange",
              component: () =>
                import("./views/oms/finance/invoice_exchange/index.vue"),
            },
            {
              path: "invoice-exchange/create/:ids",
              name: "Invoice Exchange Create",
              component: () =>
                import(
                  "./views/oms/finance/invoice_exchange/invoice-exchange-form.vue"
                ),
            },
            {
              path: "invoice-exchange/edit/:id",
              name: "Invoice Exchange edit",
              component: () =>
                import(
                  "./views/oms/finance/invoice_exchange/invoice-exchange-edit.vue"
                ),
            },
            {
              path: "invoice-exchange/detail/:id",
              name: "Invoice Exchange detail",
              component: () =>
                import(
                  "./views/oms/finance/invoice_exchange/invoice-exchange-detail.vue"
                ),
            },
            {
              path: "approval/customer-invoice-exchange",
              name: "approval Customer Invoice Exchange",
              component: () =>
                import(
                  "./views/oms/finance/approval/customer_invoice_exchange/index.vue"
                ),
            },
            {
              path: "approval/customer-deposit",
              name: "Approval Customer Deposit",
              component: () =>
                import(
                  "./views/oms/finance/approval/reversal_customer_deposit_new/index.vue"
                ),
              // src\views\oms\finance\approval\reversal_customer_deposit\index.vue
            },
            {
              path: "approval/credit-note-reversal",
              name: "Approval Credit Note Reversal",
              component: () =>
                import("./views/oms/approval_/credit-note-reversal/index.vue"),
            },
            {
              path: "approval/write-off-reversal",
              name: "Approval Write Off Reversal",
              component: () =>
                import("./views/oms/approval_/write-off-reversal/index.vue"),
            },
            {
              path: "credit-note-supplier",
              name: "credit-note-supplier",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/credit_note_supplier/index.vue"),
            },
            {
              path: "credit-note-supplier/adjustment",
              name: "credit-note-supplier-adjustment",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/cn_supplier_adjustment/index.vue"),
            },
            {
              path: "credit-note-supplier/:view",
              name: "credit-note-supplier-approval",
              // meta: { url: "/", parent: "" },
              // redirect: "/pages/login"
              component: () =>
                import("./views/oms/finance/credit_note_supplier/index.vue"),
            },
          ],
        },
        {
          path: "credit-limit-review",
          component: () => import("./views/oms/credit_limit_review/index.vue"),
          children: [
            {
              path: "credit-limit-request",
              name: "credit-limit-request",
              component: () =>
                import(
                  "./views/oms/credit_limit_review/credit_limit_request/index.vue"
                ),
            },
            {
              path: "credit-limit-reguler",
              name: "credit-limit-reguler",
              component: () =>
                import(
                  "./views/oms/credit_limit_review/credit_limit_reguler/index.vue"
                ),
            },
            {
              path: "credit-limit-reguler-import",
              name: "credit-limit-reguler-import",
              component: () =>
                import(
                  "./views/oms/credit_limit_review/credit_limit_reguler/import/index.vue"
                ),
            },
          ],
        },
        {
          path: "/account-payable",
          component: () => import("./views/oms/account_payable/index"),
          children: [
            {
              path: "vendor-invoice",
              name: "vendor-invoice",
              meta: {
                url: "/account-payable/vendor-invoice",
                parent: "Account Payable",
              },
              component: () =>
                import("./views/oms/account_payable/vendor_invoice/index.vue"),
            },
            {
              path: "vendor-invoice-ocr",
              name: "vendor-invoice-ocr",
              meta: {
                url: "/account-payable/vendor-invoice-ocr",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/vendor_invoice_ocr/index.vue"
                ),
            },
            {
              path: "vendor-invoice-template-ocr",
              name: "vendor-invoice-template-ocr",
              meta: {
                url: "/account-payable/vendor-invoice-template-ocr",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/vendor_invoice_ocr_template/index.vue"
                ),
            },
            {
              path: "vendor-invoice-template-laf",
              name: "vendor-invoice-template-laf",
              meta: {
                url: "/account-payable/vendor-invoice-template-laf",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/vendor_invoice_ocr_template_laf/index.vue"
                ),
            },
            {
              path: "/po/validation-invoice",
              name: "validation-invoice",
              meta: {
                url: "/po/validation-invoice",
                parent: "Account Payable",
              },
              component: () => import("./views/oms/invalid_invoice/index.vue"),
            },
            // {
            //   path: "/po/invalid-invoice",
            //   name: "invalid-invoice",
            //   meta: {
            //     url: "/po/invalid-invoice",
            //     parent: "Account Payable"
            //   },
            //   component: () => import("./views/oms/invalid_invoice/index.vue"),
            // },
            {
              path: "validation-invoice-non-trade",
              name: "validation-invoice-non-trade",
              meta: {
                url: "/account-payable/validation-invoice-non-trade",
                parent: "Account Payable",
              },
              component: () => import("./views/oms/invoice/po_nt/index.vue"),
            },
            {
              path: "upload-inv",
              name: "upload-inv",
              meta: {
                url: "/account-payable/upload-inv",
                parent: "Account Payable",
              },
              component: () =>
                import("./views/oms/account_payable/upload_inv/index.vue"),
            },
            {
              path: "payment-proposal",
              name: "payment-proposal",
              meta: {
                url: "/account-payable/payment-proposal",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/payment_proposal/index.vue"
                ),
            },
            {
              path: "payment",
              name: "payment",
              // meta: { url: "/account-payable/payment", parent: "Account Payable" },
              component: () =>
                import("./views/oms/account_payable/payment/index.vue"),
            },
            {
              path: "payment-view",
              name: "payment-view",
              // meta: { url: "/account-payable/payment", parent: "Account Payable" },
              component: () =>
                import("./views/oms/account_payable/payment_view/index.vue"),
            },
            {
              path: "dn-po",
              name: "dn-po",
              meta: {
                url: "/account-payable/dn-po",
                parent: "Account Payable",
              },
              component: () =>
                import("./views/oms/account_payable/dn_po/index.vue"),
            },
            {
              path: "dn-without-po-ref",
              name: "dn-without-po-ref",
              meta: {
                url: "/account-payable/dn-without-po-ref",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/dn_po_without_ref/index.vue"
                ),
            },
            {
              path: "dn-with-or-without-po-ref",
              name: "dn-with-or-without-po-ref",
              meta: {
                url: "/account-payable/dn-with-or-without-po-ref",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/dn_po_with_or_without_ref/index.vue"
                ),
            },
            {
              path: "/account-payable/dn-with-or-without-po-ref/dn-form",
              name: "dn-with-or-without-po-ref-form",
              // meta: {
              //   url: "/account-payable/dn-with-or-without-po-ref/dn-form",
              //   parent: "Account Payable",
              // },
              component: () =>
                import(
                  "./views/oms/account_payable/dn_po_with_or_without_ref/debit_note_form/index.vue"
                ),
            },
            {
              path: "/account-payable/dn-with-or-without-po-ref/dn-edit/:id",
              name: "dn-with-or-without-po-ref-edit",
              // meta: {
              //   url: "/account-payable/dn-with-or-without-po-ref/dn-form",
              //   parent: "Account Payable",
              // },
              component: () =>
                import(
                  "./views/oms/account_payable/dn_po_with_or_without_ref/debit_note_edit/index.vue"
                ),
            },
            {
              path: "dn-manual",
              name: "dn-manual",
              meta: {
                url: "/account-payable/dn-manual",
                parent: "Account Payable",
              },
              component: () =>
                import("./views/oms/account_payable/dn_manual/index.vue"),
            },
            {
              path: "dn-manual/:code/detail/:status",
              name: "dn-manual-detail",
              meta: {
                url: "/account-payable/dn-manual",
                parent: "Account Payable",
              },
              component: () =>
                import("./views/oms/account_payable/dn_manual/index.vue"),
            },
            // {
            //   path: "dn-manual/print/:id",
            //   name: "dn-manual-print",
            //   component: () => import("@/views/pages/Invoice.vue")
            // },
            {
              path: "dn-manual-adjustment",
              name: "DN Manual Adjustment",
              meta: {
                url: "/account-payable/dn-manual-adjustment",
                parent: "Account Payable",
              },
              component: () =>
                import(
                  "./views/oms/account_payable/dn_manual_adjustment/index.vue"
                ),
            },
            {
              path: "advance-payment",
              name: "advance-payment",
              // meta: { url: "/account-payable/advance-payment" },
              component: () =>
                import("./views/oms/account_payable/advance_payment/index.vue"),
            },
            {
              path: "apply-dn",
              name: "apply-dn",
              // meta: { url: "/account-payable/advance-payment" },
              component: () =>
                import("./views/oms/account_payable/apply_dn/index.vue"),
            },
            {
              path: "payment-for-dn",
              name: "payment-for-dn",
              // meta: { url: "/account-payable/advance-payment" },
              component: () =>
                import("./views/oms/account_payable/payment_for_dn/index.vue"),
            },
            {
              path: "supplier-payment-receive",
              name: "supplier-payment-receive",
              // meta: { url: "/account-payable/advance-payment" },
              component: () =>
                import(
                  "./views/oms/account_payable/supplier_payment_receive/index.vue"
                ),
            },
            {
              path: "advance-invoice",
              name: "advance-invoice",
              // meta: { url: "/account-payable/advance-invoice" },
              component: () =>
                import("./views/oms/account_payable/advance_invoice/index.vue"),
            },
            {
              path: "apply-advance-payment",
              name: "apply-advance-payment",
              // meta: { url: "/account-payable/advance-invoice" },
              component: () =>
                import("./views/oms/account_payable/apply_advance_invoice/index.vue"),
            },
          ],
        },
        {
          path: "/sales-force-automation",
          component: () => import("./views/oms/sales-force-automation"),
          children: [
            {
              path: "customer-visit-plan",
              name: "customer-visit-plan",
              component: () =>
                import(
                  "./views/oms/sales-force-automation/customer-visit-plan/index.vue"
                ),
            },
            {
              path: "customer-visit-plan/:id/plan",
              name: "customer-visit-plan-plan",
              component: () =>
                import(
                  "./views/oms/sales-force-automation/customer-visit-plan/plan/index.vue"
                ),
            },
            {
              path: "customer-visit",
              name: "customer-visit",
              component: () =>
                import(
                  "./views/oms/sales-force-automation/customer-visit/index.vue"
                ),
            },
          ],
        },
        {
          path: "/free-good",
          name: "free-good",
          meta: { url: "/free-good", parent: "" },
          component: () => import("./views/oms/report/free-good"),
        },
        {
          path: "customer-return",
          component: () => import("./views/oms/customer-return"),
          children: [
            {
              path: "sales-return-v2",
              name: "sales-return-v2",
              component: () =>
                import("./views/oms/customer-return/sales-return-v2/index.vue"),
            },
            {
              path: "sales-return-v2/form",
              name: "sales-return-v2-form",
              component: () =>
                import("./views/oms/customer-return/sales-return-v2/form.vue"),
            },
          ],
        },
        {
          path: "/canvas",
          component: () => import("./views/oms/canvas"),
          children: [
            {
              path: "item-request",
              name: "canvas.item-request",
              component: () =>
                import("./views/oms/canvas/item-request/index.vue"),
            },
            {
              path: "create",
              name: "canvas.item-request-create",
              component: () =>
                import("./views/oms/canvas/item-request/Form.vue"),
            },
            {
              path: "edit/:id",
              name: "canvas.item-request-edit",
              component: () =>
                import("./views/oms/canvas/item-request/Form.vue"),
            },
            {
              path: "detail/:id",
              name: "canvas.item-request-detail",
              component: () =>
                import("./views/oms/canvas/item-request/detail.vue"),
            },
            {
              path: "detail-on-process/:id",
              name: "canvas.item-request-detail-on-process",
              component: () =>
                import(
                  "./views/oms/canvas/item-request/detailConfirmedOnProcess.vue"
                ),
            },
            {
              path: "detail-receive/:id",
              name: "canvas.item-request-unload-detail-receive",
              component: () =>
                import("./views/oms/canvas/item-request/detailReceive.vue"),
            },
            {
              path: "detail-check/:id",
              name: "canvas.item-request-unload-detail-check",
              component: () =>
                import("./views/oms/canvas/item-request/detailCheck.vue"),
            },
            {
              path: "detail-putaway/:id",
              name: "canvas.item-request-unload-detail-putaway",
              component: () =>
                import("./views/oms/canvas/item-request/detailPutaway.vue"),
            },
          ],
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue"),
        },
        {
          path: "/pages/change-log",
          name: "page-change-log",
          component: () => import("@/views/pages/ChangeLog.vue"),
        },
        {
          path: "/pages/forgot-password",
          name: "forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
        },
        {
          path: "/pages/reset-password/:id",
          name: "reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
        },
        // {
        //   path: "/pages/reset-password/:id",
        //   name: "reset-password",
        //   component: () => import("@/views/pages/ResetPassword.vue"),
        // },
        {
          path: "/version",
          name: "version",
          component: () => import("@/views/pages/version.vue"),
        },
        {
          path: "/term-of-service",
          name: "page-term-of-service",
          component: () => import("@/views/pages/TermOfService.vue"),
        },
        {
          path: "/pages/privacy-police",
          name: "page-privacy-police",
          component: () => import("@/views/pages/PrivacyPolice.vue"),
        },
        {
          path: "/privacy-police",
          name: "page-privacy-police",
          component: () => import("@/views/pages/PrivacyPolice.vue"),
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
        },
        {
          path: "/pages/invoice/:id",
          name: "invoice-print",
          component: () => import("@/views/pages/Invoice.vue"),
        },
        {
          path: "/pages/invoice-consignment-sales/:id",
          name: "invoice-consignment-sales-print",
          component: () => import("@/views/pages/Invoice.vue"),
        },
        {
          path: "/pages/dn-manual/:id",
          name: "dn-manual-print",
          component: () => import("@/views/pages/DebitNote.vue"),
        },
        {
          path: "/pages/dn-manual-all",
          name: "dn-manual-print-all",
          component: () => import("@/views/pages/DebitNoteAll.vue"),
        },
        {
          path: "/pages/import-log/:id",
          name: "import-log-print",
          component: () => import("@/views/pages/ImportLog.vue"),
        },
        {
          path: "/pages/import-log-payment/:id",
          name: "import-log-payment-print",
          component: () => import("@/views/pages/ImportLogPayment.vue"),
        },
        {
          path: "/auth/callback",
          name: "auth-callback",
          component: () => import("@/views/auth/callback.vue"),
        },
        {
          path: "/pages/cn-supplier/:id",
          name: "cn-supplier-print",
          component: () => import("@/views/pages/CreditNoteSupplier.vue"),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
    {
      path: "/canvas/print-unloading/:id",
      name: "canvas.item-request/print-unloading",
      component: () =>
        import("./views/oms/canvas/item-request/print-unloading.vue"),
    },
    {
      path: "/canvas/print-item-request/:id",
      name: "canvas.item-request-print",
      component: () =>
        import("./views/oms/canvas/item-request/print-item-request.vue"),
    },
    {
      path: "/customer-return/sales-return-v2/pdf_old",
      name: "sales-return-v2-pdf-ori",
      component: () =>
        import("./views/oms/customer-return/sales-return-v2/pdf_old.vue"),
    },
    {
      path: "/customer-return/sales-return-v2/pdf",
      name: "sales-return-v2-pdf",
      component: () =>
        import("./views/oms/customer-return/sales-return-v2/pdf.vue"),
    },
    {
      path: "/account-payable/dn-with-or-without-po-ref/print-tax-return/:id",
      name: "debit-note.print",
      component: () =>
        import(
          "./views/oms/account_payable/dn_po_with_or_without_ref/approved/print.vue"
        ),
    },
    {
      path: "/finance/credit-note/print-tax-return/:id",
      name: "debit-note.print",
      component: () => import("./views/oms/finance/credit_note/print.vue"),
    },
    {
      path: "/account-payable/dn-with-or-without-po-ref-draft/print-tax-return/:id",
      name: "debit-note-draft.print",
      component: () =>
        import(
          "./views/oms/account_payable/dn_po_with_or_without_ref/draft/print.vue"
        ),
    },
    {
      path: "/billing/print-invoice",
      name: "invoice.print",
      component: () => import("./views/oms/billing/invoice-print.vue"),
    },
    {
      path: "/account-payable/dn-with-or-without-po-ref/debit-note-print",
      name: "debit-note.print",
      component: () =>
        import(
          "./views/oms/account_payable/dn_po_with_or_without_ref/debit-note-print.vue"
        ),
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
router.beforeEach(routerGuard);
export default router;
