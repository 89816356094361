<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
    <vs-popup
      class="holamundo"
      :fullscreen="true"
      title="Term of Service"
      :active.sync="popupTos"
    >
      <tos />
      <div class="vx-col w-1/3">
        <div class="w-1/1 w-full">
          <div class="mb-4">
            <input type="checkbox" />Agree with
            <b>ZestHub Term of Service</b> Above.
          </div>
        </div>
        <div class="w-1/1 w-full">
          <div class="mb-4">
            <vs-button
              @click="handleTos()"
              icon-pack="feather"
              icon="icon-check"
              class="mr-4"
              >Submit</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import tos from "./views/pages/TermOfService.vue";
import Vue from "vue";
import moment from "moment";
// import Vue from 'vue'

export default {
  components: {
    tos: tos
  },
  data() {
    return {
      vueAppClasses: [],
      popupTos: false
    };
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$route.name"(val) {
      if (val != "change-password") {
        if (this.$store.state.user.currentUser.is_show_tos == true) {
          this.popupTos = true;
        }
        const date =
          this.$store.state.user.currentUser.password_change_date ==
          "0001-01-01T00:00:00Z"
            ? ""
            : this.$store.state.user.currentUser.password_change_date;
        let isChangeRequired = false;

        if (!this.isDate(date)) {
          isChangeRequired = true;
        } else {
          const passwordChangeDate = moment(date);
          const currentDate = moment();
          const diff = currentDate.diff(passwordChangeDate, "days");

          if (diff >= 182) {
            isChangeRequired = true;
          }
        }

        if (isChangeRequired) {
          if (val != "home") {
            this.$router.push("/home");
            return;
          }

          this.$vs.dialog({
            type: "alert",
            color: "danger",
            title: `Change Password`,
            text: "Please change your ZestHub password",
            acceptText: "Change Password",
            closedOnBackdrop: false,
            closed: true,
            buttonCancel: "disabled",
            accept: () => {
              this.$router.push("/profile/change-password");
            }
          });

          setTimeout(() => {
            document
              .querySelectorAll(".vs-dialog-danger .vs-dialog-header i")
              .forEach(el => {
                el.style.display = "none";
              });
          }, 0);
        }
      }
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    isDate: date => {
      return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleTos() {
      this.$http.put("api/v1/setting/tos").then(resp => {
        setInterval(() => {
          location.reload();
        }, 2000);
      });
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // this.$http.get('/api/v1/setting/user-personal').then(
    //   result =>{
    //     Vue.prototype.$userLogin = result.data
    //     // console.log(result.data)

    //   }
    // )
    Vue.prototype.$vs = this.$vs;
  },
  async created() {
    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style>
.multiselect--active {
  z-index: 99999 !important;
}
.multiselect__content-wrapper {
  z-index: 99999 !important;
}
</style>
